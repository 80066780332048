import React from 'react';
import iconArrowRight from '../images/icons/icon-arrow-right.png';
import {Col, Row} from 'react-bootstrap';
import FormInformation from '../components/form-information';
import Layout from '../components/layout';

import './styles.scss';
import '../styles/main.scss';

export default function About() {
  return (
    <>
      <Layout>
        <article>
          <div className="banner-about"></div>
        </article>
        <article>
          <div className="about-us-page">
            <div className="container-wrap">
              <Row>
                <Col className="breadcrumb-page">
                  <div className="breadcrumb-default">
                    <span>Trang chủ</span>
                  </div>
                  <div className="breadcrumb-icon">
                    <img src={iconArrowRight} alt="" />
                  </div>
                  <div className="breadcrumb-present">
                    <span>Sunshine TECH</span>
                  </div>
                </Col>
              </Row>
              <Row className="about-us-wrap">
                <Col xl={8} lg={8} md={8} className="about-us-left">
                  <div className="about-us-body">
                    <div className="about-us-content">
                      <div className="about-us-title">
                        <h2>Basics Of Buying A Telescope h2</h2>
                      </div>
                      <div className="about-us-desc-first">
                        <p>
                          Of all of the celestial bodies that capture our
                          attention and fascination as astronomers, none has a
                          greater influence on life on planet Earth than it’s
                          own satellite, the moon. When you think about it, we
                          regard the moon with such powerful significance that
                          unlike the moons of other planets which we give names,
                          we only refer to our one and only orbiting orb as THE
                          moon. It is not a moon.
                        </p>
                      </div>
                      <div className="about-us-sub_title">
                        <h4>Astronomy or astrology h4</h4>
                      </div>
                      <div className="about-us-desc-seconds">
                        <p>
                          No matter how far along you are in your sophistication
                          as an amateur astronomer, there is always one
                          fundamental moment that we all go back to. That is
                          that very first moment that we went out where you
                          could really see the cosmos well and you took in the
                          night sky. For city dwellers, this is a revelation as
                          profound as if we discovered aliens living among us.
                          Most of us have no idea the vast panorama of lights
                          that dot a clear night sky when there are no city
                          lights to interfere with the view.
                        </p>
                        <ul className="desc-second-list">
                          <li>Internet Advertising Trends You Won</li>
                          <li>How To Write Better Advertising Copy</li>
                          <li>Stair Lifts Feel The Freedom Of Your Home</li>
                        </ul>
                      </div>
                      <div className="about-us-sub_title">
                        <h4>Adwords Keyword Research For Beginners h4</h4>
                      </div>
                      <div className="about-us-desc-last">
                        <p>
                          No matter how far along you are in your sophistication
                          as an amateur astronomer, there is always one
                          fundamental moment that we all go back to. That is
                          that very first moment that we went out where you
                          could really see the cosmos well and you took in the
                          night sky. For city dwellers, this is a revelation as
                          profound as if we discovered aliens living among us.
                          Most of us have no idea the vast panorama of lights
                          that dot a clear night sky when there are no city
                          lights to interfere with the view.
                        </p>
                      </div>
                    </div>
                  </div>
                </Col>
                <Col xl={4} lg={4} md={4} className="about-us-right">
                  <FormInformation />
                </Col>
              </Row>
            </div>
          </div>
        </article>
      </Layout>
    </>
  );
}
